import { Action, createActionGroup, emptyProps, props } from '@ngrx/store';
import { TemplateDatoPers } from '../dato-personalizzato.model';
import { NgRxUtils } from '../../../shared/utility/NgRxUtils';

/* DEFAULT ACTIONS */
export const DatoPersonalizzatoApiActions = createActionGroup({
  source: 'Templates/API',
  events: {
    'all template dato pers': emptyProps(),
    'all template dato pers success': props<{ datiPersonalizzati: TemplateDatoPers[] }>(),
    'all template dato pers failure': NgRxUtils.httpStatusProps(),

    'delete template dato pers': props<{ id: string}>(),
    'delete template dato pers success': props<{ id: string}>(),
    'delete template dato pers failure': NgRxUtils.httpStatusProps(),

    'get template dato pers by id': props<{ id: string}>(),
    'get template dato pers by id success': props<{ template: TemplateDatoPers }>(),
    'get template dato pers by id failure': NgRxUtils.httpStatusProps(),

    'create template dato pers': props<{ template: Partial<TemplateDatoPers>, redirectToDetail: boolean, actions?: Action[] }>(),
    'create template dato pers success': props<{ template: TemplateDatoPers }>(),
    'create template dato pers failure': NgRxUtils.httpStatusProps(),

    'update template dato pers': props<{ template: TemplateDatoPers, actions?: Action[] }>(),
    'update template dato pers success': props<{ template: TemplateDatoPers }>(),
    'update template dato pers failure': NgRxUtils.httpStatusProps(),

    'set selected template dato pers id': props<{ id: string}>(),
    'set selected template dato pers': props<{template: TemplateDatoPers}>()

  }
});

/* API ACTIONS */
export const DatoPersonalizzatoDefaultActions = createActionGroup({
  source: 'Templates/DefaultActions',
  events: {
    'clear errors': emptyProps(),
    'clear state': emptyProps()
  }
});
